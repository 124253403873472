import React from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { QuintypeIcon } from "../../atoms/icon";
import "./styles.m.css";

const staticItems = {
  poweredBy: "Powered by Quintype",
  url: "https://www.quintype.com/"
};

const topRowItems = footerLinks => (
  <div styleName="footer-row-1">
    {footerLinks.map((item, index) => {
      return item.isExternalLink ? (
        <a href={item.completeUrl} key={index} target="_blank" rel="noopener noreferrer" styleName="link">
          {item.title}
        </a>
      ) : (
        <Link key={index} href={item.completeUrl} styleName="link">
          {item.title}
        </Link>
      );
    })}
  </div>
);

const bottomRowItems = getCopyright => (
  <div styleName="footer-row-2">
    <div className="copyright-text">{getCopyright && getCopyright.copyright}</div>
    <div className="powered-by">
      <QuintypeIcon color="#fff" width={24} height={24} />
      <a href={staticItems.url} target="_blank" rel="noopener noreferrer">
        {staticItems.poweredBy}
      </a>
    </div>
  </div>
);

const FooterBase = ({ footerLinks, getCopyright }) => (
  <>
    <div styleName="footer">
      <div className="container">
        {topRowItems(footerLinks)}
        {bottomRowItems(getCopyright)}
      </div>
    </div>
    <div className="OUTBRAIN" data-widget-id="GS_1"></div>
    <div
      dangerouslySetInnerHTML={{
        __html: `<script type="text/javascript" async="async" src="//widgets.outbrain.com/outbrain.js"></script>`
      }}
    />
  </>
);

function mapStateToProps(state) {
  return {
    footerLinks: get(state, ["qt", "data", "navigationMenu", "footer"], []) || {},
    getCopyright: get(state, ["qt", "config", "publisher-settings"], []) || {}
  };
}

FooterBase.propTypes = {
  footerLinks: PT.arrayOf(
    PT.shape({
      isExternalLink: PT.bool,
      completeUrl: PT.string,
      title: PT.string
    })
  ),
  getCopyright: PT.object
};

export const Footer = connect(
  mapStateToProps,
  null
)(FooterBase);
