import React from "react";
import PT from "prop-types";
import { Link } from "@quintype/components";
import { ImageCard16x9, SubHeadline, SectionName, Headline, ByName } from "../../atoms";
import { getHeadline } from "../../helper/utils";
import "./styles.m.css";

export const StoryTileWithContent = ({ story }) => {
  const section = story.sections[0];

  return (
    <div styleName="wrapper">
      <div styleName="image-wrapper">
        <Link href={`/${story.slug}`}>
          <ImageCard16x9 story={story} />
        </Link>
      </div>
      <div styleName="text-block">
        <div styleName="story-block">
          <Link href={`/${section.slug}`}>
            <SectionName name={section.name} />
          </Link>
          <Link href={`/${story.slug}`}>
            <Headline value={getHeadline(story)} fontSize="larger" lines={2} />
          </Link>
          <ByName authors={story.authors} />
        </div>
        <div styleName="subheadline">
          {story && story.subheadline ? (
            <SubHeadline value={story.subheadline.replace("&amp;", "&")} lines={4} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

StoryTileWithContent.propTypes = {
  story: PT.object.isRequired
};
